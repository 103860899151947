<div style="z-index: 1040"
  [ngClass]="{ 'overlay  nsm-overlay-open ': modalOpen, 'eighteen-plus': modalOpen && isBackgroundDarker }">
  <!-- Registration Modal -->
  <div class="nsm-dialog nsm-dialog-animation-fade nsm-dialog-open signUpModal"
    ng-reflect-ng-class="nsm-dialog,nsm-dialog-animatio" aria-hidden="false" role="dialog" tabindex="-1"
    aria-modal="true" style="z-index: 1041" *ngIf="modalOpen && modalName === MODAL_NAME_SIGNUP">
    <div class="nsm-content">
      <div class="nsm-body">
        <div class="c-modal__header">
          <h5 class="c-modal__title">
            <!-- Register -->
            <span *ngIf="generalService.translationMode === true">
              (G004)
            </span>
            {{ "GLOBAL.G004.value" | translate }}
          </h5>
          <button aria-label="Close" (click)="closeModal()"
            class="nsm-dialog-btn-close c-modal__close js-toggle-modal close" data-target="#sv-authRegister-modal"
            type="button">
            <span class="is-icon icon-close"></span>
          </button>
        </div>
        <div class="c-modal__body">
          <div class="c-formItem">
            <div class="c-formGroup">
              <label class="c-formGroup__label c-formGroup__label--req">
                <!-- Name -->
                <span *ngIf="generalService.translationMode === true">
                  (A001)
                </span>
                {{ "AUTH.A001.value" | translate }}
              </label>
              <div class="c-formGroup__control">
                <input type="text" class="c-input" name="" [(ngModel)]="authObj.name" />
              </div>
            </div>
          </div>
          <div class="c-formItem">
            <div class="c-formGroup">
              <label class="c-formGroup__label c-formGroup__label--req">
                <!-- Name -->
                <span *ngIf="generalService.translationMode === true">
                  (A002)
                </span>
                {{ "AUTH.A002.value" | translate }}
              </label>
              <div class="c-formGroup__control">
                <input type="text" class="c-input" name="" [(ngModel)]="authObj.lastName" />
              </div>
            </div>
          </div>
          <div class="c-formItem">
            <div class="c-formGroup">
              <label class="c-formGroup__label c-formGroup__label--req">
                <!-- Phone -->
                <span *ngIf="generalService.translationMode === true">
                  (G009)
                </span>
                {{ "GLOBAL.G009.value" | translate }}
              </label>
              <div class="c-formGroup__control">
                <input type="text" class="c-input" name="" [(ngModel)]="authObj.phone" />
              </div>
            </div>
          </div>
          <div class="c-formItem" *ngIf="isBirthDateField">
            <div class="c-formGroup">
              <label class="c-formGroup__label c-formGroup__label--req">
                <!-- Name -->
                <span *ngIf="generalService.translationMode === true">
                  (A034)
                </span>
                {{ "AUTH.A034.value" | translate }}
              </label>
              <div class="c-formGroup__control">
                <input type="date" class="c-input" name="" [(ngModel)]="authObj.birthDate" />
              </div>
            </div>
          </div>
          <div class="c-formItem">
            <div class="c-formGroup">
              <label class="c-formGroup__label c-formGroup__label--req">
                <!-- Email -->
                <span *ngIf="generalService.translationMode === true">
                  (G008)
                </span>
                {{ "GLOBAL.G008.value" | translate }}
              </label>
              <div class="c-formGroup__control">
                <input type="email" class="c-input" name="" [(ngModel)]="authObj.email" />
              </div>
            </div>
          </div>
          <div class="c-formItem">
            <div class="c-formGroup">
              <label class="c-formGroup__label c-formGroup__label--req">
                <!-- Password -->
                <span *ngIf="generalService.translationMode === true">
                  (G005)
                </span>
                {{ "GLOBAL.G005.value" | translate }}
              </label>
              <div class="c-formGroup__control">
                <input type="password" class="c-input" name="" [(ngModel)]="authObj.pass" />
              </div>
            </div>
          </div>
          <div class="c-checkbox c-formItem">
            <input id="signUpTermsAccepted" type="checkbox" class="c-checkbox__input"
              (change)="acceptDeliveryTerms($event)" [checked]="signUpTermsAccepted" />
            <label class="c-checkbox__label c-dataBox__note" for="signUpTermsAccepted">
              <!-- I have read and agree to our -->
              <span>
                <span *ngIf="generalService.translationMode === true">
                  (A004)
                </span>
                {{ "AUTH.A004.value" | translate }}
                <span *ngIf="generalService.translationMode === true">
                  (G042)
                </span>
                {{ parentCompany }}
                {{ "GLOBAL.G042.value" | translate }}
              </span>
              <a *ngIf="
                  siteConfigService.siteConfig.dynamicPages &&
                  siteConfigService.siteConfig.dynamicPages.policy
                " [href]="siteConfigService.siteConfig.dynamicPages.policy.link" target="_blank">
                <span *ngIf="generalService.translationMode === true">
                  (A005)
                </span>
                {{ "AUTH.A005.value" | translate }}
              </a>
            </label>
          </div>
          <br />
          <div class="c-formGroup">
            <div class="c-formGroup__helpText">
              {{ errors.signUpError }}
            </div>
          </div>
          <div class="c-buttonGroup c-buttonGroup--submit">
            <button class="b--lg" [ngClass]="{
              'b-action':
              signUpTermsAccepted && authObj.name && authObj.lastName && authObj.phone && authObj.email && authObj.pass && (!isBirthDateField || (isBirthDateField && authObj.birthDate)),
            'b-default':
              !signUpTermsAccepted || !authObj.name || !authObj.lastName || !authObj.phone || !authObj.email || !authObj.pass || (isBirthDateField && !authObj.birthDate)
              }" (click)="signUpViaEmail()" [disabled]="
              !signUpTermsAccepted ||
              !authObj.name ||
              !authObj.lastName ||
              !authObj.phone ||
              !authObj.email ||
              !authObj.pass ||
              (isBirthDateField && !authObj.birthDate) ||
              interactionsDisabled
              ">
              <span *ngIf="generalService.translationMode === true">
                (G003)
              </span>
              {{ "GLOBAL.G003.value" | translate }}
            </button>
            <button class="b-default b--lg" (click)="closeModal()">
              <!-- Cancel -->

              <span *ngIf="generalService.translationMode === true">
                (G018)
              </span>
              {{ "GLOBAL.G018.value" | translate }}
            </button>
          </div>
        </div>
        <div class="c-modal__footer"></div>
      </div>
    </div>
  </div>

  <!-- SignIn Modal -->
  <div class="nsm-dialog nsm-dialog-animation-fade nsm-dialog-open" ng-reflect-ng-class="nsm-dialog,nsm-dialog-animatio"
    aria-hidden="false" role="dialog" tabindex="-1" aria-modal="true" style="z-index: 1041"
    *ngIf="modalOpen && modalName === 'signInModal'">
    <div class="nsm-content">
      <div class="nsm-body">
        <div class="c-modal__header">
          <h5 class="c-modal__title">
            <span *ngIf="generalService.translationMode === true">
              (G002)
            </span>
            {{ "GLOBAL.G002.value" | translate }}
          </h5>
          <button aria-label="Close" class="nsm-dialog-btn-close c-modal__close js-toggle-modal close" type="button"
            (click)="closeModal()">
            <span class="is-icon icon-close"></span>
          </button>
        </div>
        <div class="c-modal__body">
          <sv-auth></sv-auth>
        </div>
        <div class="c-modal__footer"></div>
      </div>
    </div>
  </div>

  <!-- Forgot Password Modal -->
  <div class="nsm-dialog nsm-dialog-animation-fade nsm-dialog-open" ng-reflect-ng-class="nsm-dialog,nsm-dialog-animatio"
    aria-hidden="false" role="dialog" tabindex="-1" aria-modal="true" style="z-index: 1041"
    *ngIf="modalOpen && modalName === 'forgotPasswordModal'">
    <div class="nsm-content">
      <div class="nsm-body">
        <div class="c-modal__header">
          <h5 class="c-modal__title">
            <span *ngIf="generalService.translationMode === true">
              (A011)
            </span>
            {{ "AUTH.A011.value" | translate }}
          </h5>
          <button aria-label="Close" class="nsm-dialog-btn-close c-modal__close js-toggle-modal close" type="button"
            (click)="closeModal()">
            <span class="is-icon icon-close"></span>
          </button>
        </div>
        <div class="c-modal__body">
          <div class="c-formItem">
            <div class="c-formGroup">
              <label class="c-formGroup__label c-formGroup__label--req">
                <!-- Email -->
                <span *ngIf="generalService.translationMode === true">
                  (G008)
                </span>
                {{ "GLOBAL.G008.value" | translate }}
              </label>
              <div class="c-formGroup__control">
                <input type="email" class="c-input" name="forgotPasswordEmail" [(ngModel)]="forgotPasswordEmail" />
              </div>
            </div>
          </div>

          <div class="c-formGroup">
            <label class="c-formGroup__label">
              <!-- Choose where you would like us to send the link to reset the
              password: -->
              <span *ngIf="generalService.translationMode === true">
                (A028)
              </span>
              {{ "AUTH.A028.value" | translate }}
            </label>
            <div class="c-formGroup__control">
              <div style="margin-top: 0.5em">
                <label class="c-radio"><input type="radio" name="restPassword" class="c-radio__input"
                    (click)="handleForgotPasswordMethod('email')" [checked]="
                      defaultForgotPasswordMethod === 'email' ? true : false
                    " />
                  <span class="c-radio__label">
                    <!-- to your <strong>email</strong> address -->
                    <span *ngIf="generalService.translationMode === true">
                      (A029)
                    </span>
                    {{ "AUTH.A029.value" | translate }}
                  </span>
                </label>
              </div>
              <div style="margin-top: 1em" *ngIf="
                  siteConfigService.siteConfig.auth.smsPasswordRecovery === true
                ">
                <label class="c-radio"><input type="radio" name="restPassword" class="c-radio__input"
                    (click)="handleForgotPasswordMethod('text_message')" [checked]="
                      defaultForgotPasswordMethod === 'text_message'
                        ? true
                        : false
                    " />
                  <span class="c-radio__label">
                    <!-- to the <strong>mobile</strong> associated with your account -->
                    <span *ngIf="generalService.translationMode === true">
                      (A026)
                    </span>
                    {{ "AUTH.A026.value" | translate }}
                  </span>
                </label>
              </div>
            </div>
          </div>

          <div class="c-formItem">
            <button class="b-action b--lg b--wide" (click)="handleForgotPassword()">
              <span *ngIf="generalService.translationMode === true">
                (G034)
              </span>
              {{ "GLOBAL.G034.value" | translate }}
            </button>
          </div>
        </div>
        <div class="c-modal__footer"></div>
      </div>
    </div>
  </div>

  <!-- Reset Password Modal -->
  <div class="nsm-dialog nsm-dialog-animation-fade nsm-dialog-open" ng-reflect-ng-class="nsm-dialog,nsm-dialog-animatio"
    aria-hidden="false" role="dialog" tabindex="-1" aria-modal="true" style="z-index: 1041"
    *ngIf="modalOpen && modalName === 'resetPasswordModal'">
    <div class="nsm-content">
      <div class="nsm-body">
        <div class="c-modal__header">
          <h5 class="c-modal__title">
            <span *ngIf="generalService.translationMode === true">
              (A012)
            </span>
            {{ "AUTH.A012.value" | translate }}
          </h5>
          <button aria-label="Close" class="nsm-dialog-btn-close c-modal__close js-toggle-modal close" type="button"
            (click)="closeModal()">
            <span class="is-icon icon-close"></span>
          </button>
        </div>
        <div class="c-modal__body">
          <div class="c-formItem">
            <div class="c-formGroup">
              <label class="c-formGroup__label c-formGroup__label--req">
                <!-- Email -->
                <span *ngIf="generalService.translationMode === true">
                  (G035)
                </span>
                {{ "GLOBAL.G035.value" | translate }}
              </label>
              <div class="c-formGroup__control">
                <input type="password" class="c-input" name="resetPassword" [(ngModel)]="resetPassword" />
              </div>
            </div>
            <div class="c-formGroup">
              <label class="c-formGroup__label c-formGroup__label--req">
                <span *ngIf="generalService.translationMode === true">
                  (G043)
                </span>
                {{ "GLOBAL.G043.value" | translate }}
              </label>
              <div class="c-formGroup__control">
                <input type="password" class="c-input" name="confirmPassword" [(ngModel)]="confirmPassword" />
              </div>
            </div>
          </div>

          <div class="c-formItem">
            <button class="b-action b--lg b--wide" (click)="handleResetPassword()">
              <span *ngIf="generalService.translationMode === true">
                (G034)
              </span>
              {{ "GLOBAL.G034.value" | translate }}
            </button>
          </div>
        </div>
        <div class="c-modal__footer"></div>
      </div>
    </div>
  </div>

  <!-- SignOut Modal -->
  <div class="nsm-dialog nsm-dialog-animation-fade nsm-dialog-open" ng-reflect-ng-class="nsm-dialog,nsm-dialog-animatio"
    aria-hidden="false" role="dialog" tabindex="-1" aria-modal="true" style="z-index: 1041"
    *ngIf="modalOpen && modalName === 'signOutModal'">
    <div class="nsm-content">
      <div class="nsm-body">
        <div class="c-modal__header">
          <h5 class="c-modal__title">
            <!-- Logout -->

            <span *ngIf="generalService.translationMode === true">
              (G021)
            </span>
            {{ "GLOBAL.G021.value" | translate }}
          </h5>
          <button aria-label="Close" class="nsm-dialog-btn-close c-modal__close js-toggle-modal close" type="button"
            (click)="closeModal()">
            <span class="is-icon icon-close"></span>
          </button>
        </div>
        <div class="c-modal__body">
          <!-- Are you sure you want to logout ? -->
          <span>
            <span *ngIf="generalService.translationMode === true">
              (A003)
            </span>
            {{ "AUTH.A003.value" | translate }}
          </span>

          <br />
          <div class="c-buttonGroup c-buttonGroup--submit">
            <button class="b-action b--lg" (click)="logOut()">
              <!-- Yes -->
              <span *ngIf="generalService.translationMode === true">
                (G023)
              </span>
              {{ "GLOBAL.G023.value" | translate }}
            </button>
            <button class="b-default b--lg" (click)="closeModal()">
              <!-- No -->
              <span *ngIf="generalService.translationMode === true">
                (G024)
              </span>
              {{ "GLOBAL.G024.value" | translate }}
            </button>
          </div>
        </div>
        <div class="c-modal__footer"></div>
      </div>
    </div>
  </div>

  <!-- Cookie Modal -->
  <div class="nsm-dialog nsm-dialog-animation-fade nsm-dialog-open" ng-reflect-ng-class="nsm-dialog,nsm-dialog-animatio"
    aria-hidden="false" role="dialog" tabindex="-1" aria-modal="true" style="z-index: 1041"
    *ngIf="modalOpen && modalName === 'cookieModal'">
    <div class="nsm-content">
      <div class="nsm-body">
        <div class="c-modal__header">
          <h5 class="c-modal__title">
            <!-- This site uses cookies -->

            <span *ngIf="generalService.translationMode === true">
              (CK001)
            </span>
            {{ "COOKIE.CK001.value" | translate }}
          </h5>
          <!-- <button
            aria-label="Close"
            (click)="closeModal()"
            class="nsm-dialog-btn-close c-modal__close js-toggle-modal close"
            data-target="#sv-authRegister-modal"
            type="button"
          >
            <span class="is-icon icon-close"></span>
          </button> -->
        </div>
        <div class="c-modal__body">
          <span *ngIf="generalService.translationMode === true"> (CK002) </span>
          {{ "COOKIE.CK002.value" | translate }}
          <a [routerLink]="'/data-protection'" target="_blank">
            <span *ngIf="generalService.translationMode === true">
              (CK003)
            </span>
            {{ "COOKIE.CK003.value" | translate }}</a>
          <br />
          <br />
          <span *ngIf="generalService.translationMode === true"> (CK004) </span>
          {{ "COOKIE.CK004.value" | translate }}

          <div class="c-buttonGroup c-buttonGroup--submit">
            <button class="b--lg" [ngClass]="{
                'b-action': true
              }" (click)="acceptCookies()">
              <span *ngIf="generalService.translationMode === true">
                (CK005)
              </span>
              {{ "COOKIE.CK005.value" | translate }}
            </button>

            <button class="b-default b--lg" (click)="declineCookies()">
              <span *ngIf="generalService.translationMode === true">
                (CK006)
              </span>
              {{ "COOKIE.CK006.value" | translate }}
            </button>
          </div>
        </div>
        <div class="c-modal__footer"></div>
      </div>
    </div>
  </div>

  <!-- Limited Availability Modal -->
  <div class="nsm-dialog nsm-dialog-animation-fade nsm-dialog-open" ng-reflect-ng-class="nsm-dialog,nsm-dialog-animatio"
    aria-hidden="false" role="dialog" tabindex="-1" aria-modal="true" style="z-index: 1041"
    *ngIf="modalOpen && modalName === 'limitedAvailabilityModal'">
    <div class="nsm-content">
      <div class="nsm-body">
        <div class="c-modal__header">
          <h5 class="c-modal__title">
            <!-- <span *ngIf="generalService.translationMode === true">
              (A012)
            </span>
            {{ "AUTH.A012.value" | translate }} -->
            <!-- Limited Availability Product -->
          </h5>
          <!-- <button
            aria-label="Close"
            class="nsm-dialog-btn-close c-modal__close js-toggle-modal close"
            type="button"
            (click)="closeModal()"
          >
            <span class="is-icon icon-close"></span>
          </button> -->
        </div>
        <div class="c-modal__body">
          <div class="c-formItem">
            <div class="c-formGroup">
              <label class="c-formGroup__label c-formGroup__label">
                <!-- This product is only available on Collico delivery method. -->
                <!-- This product may be unavailable for delivery to your area.
                Please enter your postal code above so you can be sure that this
                product can be delivered to you. -->
                <span *ngIf="generalService.translationMode === true">
                  (P002)
                </span>
                {{ "PRODUCT.P002.value" | translate }}
              </label>
            </div>
          </div>

          <div class="c-formItem">
            <div class="c-buttonGroup c-buttonGroup--submit">
              <button class="b--lg" [ngClass]="{
                  'b-action': true
                }" (click)="closeModal()">
                OK
              </button>
            </div>
          </div>
        </div>
        <div class="c-modal__footer"></div>
      </div>
    </div>
  </div>

  <!-- Limited Availability Modal 2 -->
  <div class="nsm-dialog nsm-dialog-animation-fade nsm-dialog-open" ng-reflect-ng-class="nsm-dialog,nsm-dialog-animatio"
    aria-hidden="false" role="dialog" tabindex="-1" aria-modal="true" style="z-index: 1041"
    *ngIf="modalOpen && modalName === 'limitedAvailabilityModal2'">
    <div class="nsm-content">
      <div class="nsm-body">
        <div class="c-modal__header">
          <h5 class="c-modal__title">
            <!-- <span *ngIf="generalService.translationMode === true">
              (A012)
            </span>
            {{ "AUTH.A012.value" | translate }} -->
            <!-- Limited Availability Products -->
          </h5>
          <!-- <button
            aria-label="Close"
            class="nsm-dialog-btn-close c-modal__close js-toggle-modal close"
            type="button"
            (click)="closeModal()"
          >
            <span class="is-icon icon-close"></span>
          </button> -->
        </div>
        <div class="c-modal__body">
          <div class="c-formItem">
            <div class="c-formGroup">
              <label class="c-formGroup__label c-formGroup__label">
                <!-- We do not deliver products that require temperature controlled
                transportation to your area. -->
                <span *ngIf="generalService.translationMode === true">
                  (P003)
                </span>
                {{ "PRODUCT.P003.value" | translate }}
              </label>
            </div>
          </div>

          <div class="c-formItem">
            <div class="c-buttonGroup c-buttonGroup--submit">
              <button class="b--lg" [ngClass]="{
                  'b-action': true
                }" (click)="closeModal()">
                OK
              </button>
            </div>
          </div>
        </div>
        <div class="c-modal__footer"></div>
      </div>
    </div>
  </div>

  <!-- Delivery Address Modal -->
  <div class="nsm-dialog nsm-dialog-animation-fade nsm-dialog-open" ng-reflect-ng-class="nsm-dialog,nsm-dialog-animatio"
    aria-hidden="false" role="dialog" tabindex="-1" aria-modal="true" style="z-index: 1041"
    *ngIf="modalOpen && modalName === 'deliveryAddress'">
    <div class="nsm-content">
      <div class="nsm-body">
        <div class="c-modal__header">
          <h5 class="c-modal__title">
            <span *ngIf="deliveryAddress.id">Edit</span>
            <span *ngIf="!deliveryAddress.id">New</span>
            delivery address
          </h5>
          <button aria-label="Close" class="nsm-dialog-btn-close c-modal__close js-toggle-modal close"
            data-target="#sv-modal-editAddress" type="button" (click)="closeModal()">
            <span class="is-icon icon-close"></span>
          </button>
        </div>
        <div class="c-modal__body">
          <div class="c-formGroup c-formGroup--horizontal">
            <label class="c-formGroup__label c-formGroup__label--req">
              Name
            </label>
            <div class="c-formGroup__control">
              <input required #firstname="ngModel" type="" class="c-input" name=""
                [(ngModel)]="deliveryAddress.shipping_firstname" />
              <br />
              <div *ngIf="
                  firstname.invalid && (firstname.dirty || firstname.touched)
                " class="c-formGroup c-formGroup--notValid">
                <br />
                First name is required
              </div>
            </div>
            <div class="c-formGroup__helper"></div>
          </div>

          <div class="c-formGroup c-formGroup--horizontal">
            <label class="c-formGroup__label c-formGroup__label--req">
              Surname
            </label>
            <div class="c-formGroup__control">
              <input required #lastname="ngModel" type="" class="c-input" name=""
                [(ngModel)]="deliveryAddress.shipping_lastname" />
              <br />
              <div *ngIf="lastname.invalid && (lastname.dirty || lastname.touched)"
                class="c-formGroup c-formGroup--notValid">
                <br />
                Last name is required
              </div>
            </div>
            <div class="c-formGroup__helper"></div>
          </div>

          <div class="c-formGroup c-formGroup--horizontal">
            <label class="c-formGroup__label c-formGroup__label--req">
              Email
            </label>
            <div class="c-formGroup__control">
              <input required #email="ngModel" type="email" class="c-input" email name="email"
                pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$" [(ngModel)]="deliveryAddress.shipping_email" />
              <br />
              <div *ngIf="email.dirty || email.touched" class="c-formGroup c-formGroup--notValid">
                <div *ngIf="!deliveryAddress.shipping_email">
                  <br />
                  Email is required
                </div>

                <div *ngIf="
                    email.invalid &&
                    deliveryAddress.shipping_email &&
                    deliveryAddress.shipping_email.length
                  ">
                  <br />
                  Email is invalid
                </div>
              </div>
              <!-- <div *ngIf="email.errors.pattern" class="c-formGroup__helpText">
                Email pattern is invalid
              </div> -->
            </div>
            <div class="c-formGroup__helper"></div>
          </div>

          <div class="c-formGroup c-formGroup--horizontal">
            <label class="c-formGroup__label c-formGroup__label--req">
              Phone
            </label>
            <div class="c-formGroup__control">
              <input required #phone="ngModel" type="" class="c-input" name=""
                [(ngModel)]="deliveryAddress.shipping_phone" (keyup)="numberChange($event, 'phone')" pattern=".{7,}" />
              <br />
              <div *ngIf="phone.invalid && (phone.dirty || phone.touched)" class="c-formGroup c-formGroup--notValid">
                <br />
                Phone must be minimum 7 digits
              </div>
            </div>
            <div class="c-formGroup__helper"></div>
          </div>

          <div class="c-formGroup c-formGroup--horizontal">
            <label class="c-formGroup__label c-formGroup__label--req">
              Street
            </label>
            <div class="c-formGroup__control">
              <input required #street="ngModel" type="" class="c-input" name=""
                [(ngModel)]="deliveryAddress.shipping_street" />
              <br />
              <div *ngIf="street.invalid && (street.dirty || street.touched)" class="c-formGroup c-formGroup--notValid">
                <br />
                Street address is required
              </div>
            </div>
            <div class="c-formGroup__helper"></div>
          </div>

          <div class="c-formGroup c-formGroup--horizontal">
            <label class="c-formGroup__label c-formGroup__label--req">
              City
            </label>
            <div class="c-formGroup__control">
              <input required #city="ngModel" type="" class="c-input" name=""
                [(ngModel)]="deliveryAddress.shipping_city" />
              <br />
              <div *ngIf="city.invalid && (city.dirty || city.touched)" class="c-formGroup c-formGroup--notValid">
                <br />
                City is required
              </div>
            </div>
            <div class="c-formGroup__helper"></div>
          </div>

          <div class="c-formGroup c-formGroup--horizontal">
            <label class="c-formGroup__label c-formGroup__label--req">
              Zip
            </label>
            <div class="c-formGroup__control">
              <input required #zip="ngModel" type="" class="c-input c-input--short" name=""
                [(ngModel)]="deliveryAddress.shipping_zip" (keyup)="numberChange($event, 'zip')" pattern=".{5}" />
              <br />
              <div *ngIf="zip.invalid && (zip.dirty || zip.touched)" class="c-formGroup c-formGroup--notValid">
                <br />
                Zip must be 5 digits
              </div>
            </div>
            <div class="c-formGroup__helper"></div>
          </div>

          <div class="c-formGroup c-formGroup--horizontal">
            <label class="c-formGroup__label c-formGroup__label--req">
              Country
            </label>
            <div class="c-formGroup__control">
              <input required #country="ngModel" type="" class="c-input" name="" disabled="true"
                [(ngModel)]="deliveryAddress.shipping_country" />
              <br />
              <div *ngIf="country.invalid && (country.dirty || country.touched)"
                class="c-formGroup c-formGroup--notValid">
                <br />
                Country is required
              </div>
            </div>
            <div class="c-formGroup__helper"></div>
          </div>

          <!--
          <div class="c-checkbox c-formItem">
            <input
              [id]="'defaultSelected-'"
              type="checkbox"
              class="c-checkbox__input"
              [checked]="deliveryAddress.is_default === 1"
              [disabled]="deliveryAddress.is_default === 1"
              [(ngModel)]="deliveryAddress.is_default"
            />
            <label
              class="c-checkbox__label c-dataBox__note"
              [for]="'defaultSelected-'"
            >

              <span> is default </span>
            </label>
          </div> -->

          <!-- <div class="c-formGroup c-formGroup--horizontal">
            <label class="c-formGroup__label"> Door code </label>
            <div class="c-formGroup__control">
              <input type="" class="c-input c-input--med" name="" />
            </div>
            <div class="c-formGroup__helper"></div>
          </div>

          <div class="c-formGroup c-formGroup--horizontal">
            <label class="c-formGroup__label"> Guidlaines </label>
            <div class="c-formGroup__control">
              <textarea type="" class="c-input" name=""></textarea>
            </div>
            <div class="c-formGroup__helper"></div>
          </div> -->

          <div class="c-formGroupButtons--horizontal">
            <button class="b-action b--lg" style="margin-top: 2rem" (click)="
                deliveryAddress.id
                  ? updateDeliveryAddress()
                  : addDeliveryAddress()
              ">
              Save
            </button>
            <button class="b-default b--lg" style="margin-left: 2rem" (click)="closeModal()">
              Cancel
            </button>
          </div>
        </div>
        <div class="c-modal__footer"></div>
      </div>
    </div>
  </div>

  <!-- Confirmation Modal -->
  <div class="nsm-dialog nsm-dialog-animation-fade nsm-dialog-open" ng-reflect-ng-class="nsm-dialog,nsm-dialog-animatio"
    aria-hidden="false" role="dialog" tabindex="-1" aria-modal="true" style="z-index: 1041"
    *ngIf="modalOpen && modalName === 'confirmationModal'">
    <div class="nsm-content">
      <div class="nsm-body">
        <div class="c-modal__header">
          <h5 class="c-modal__title">
            {{ modal.data.heading }}
          </h5>
          <button aria-label="Close" class="nsm-dialog-btn-close c-modal__close js-toggle-modal close" type="button"
            (click)="closeModal()">
            <span class="is-icon icon-close"></span>
          </button>
        </div>
        <div class="c-modal__body">
          {{ modal.data.message }}

          <br />
          <div class="c-buttonGroup c-buttonGroup--submit">
            <button class="b-action b--lg" (click)="confirmatoryAction()">
              <!-- Yes -->
              <span *ngIf="generalService.translationMode === true">
                (G023)
              </span>
              {{ "GLOBAL.G023.value" | translate }}
            </button>
            <button class="b-default b--lg" (click)="closeModal()">
              <!-- No -->
              <span *ngIf="generalService.translationMode === true">
                (G024)
              </span>
              {{ "GLOBAL.G024.value" | translate }}
            </button>
          </div>
        </div>
        <div class="c-modal__footer"></div>
      </div>
    </div>
  </div>

  <!-- Age Confirmation Modal -->
  <div class="nsm-dialog-age-confirmation nsm-dialog-animation-fade nsm-dialog-open ageConfirmationPopupModal"
    ng-reflect-ng-class="nsm-dialog,nsm-dialog-animatio" aria-hidden="false" role="dialog" tabindex="-1"
    aria-modal="true" style="z-index: 1041" *ngIf="modalOpen && modalName === MODAL_NAME_AGE_CONFIRMATION">
    <div class="nsm-content">
      <div class="nsm-body">
        <div class="c-modal__body">
          <div class="logo">
            <img src="{{ logo1.src }}" alt="Jaloviini">
          </div>
          <p>
            <span *ngIf="generalService.translationMode === true">
              (G053A)
            </span>
            {{ "GLOBAL.G053A.value" | translate }}
          </p>
          <br />
          <p>
            <span *ngIf="generalService.translationMode === true">
              (G053)
            </span>
            {{ "GLOBAL.G053.value" | translate }}
          </p>
        </div>
        <div class="c-modal__footer">
          <button class="b--lg b-action" (click)="acceptAgeConfirmation()">
            <!-- Yes -->
            <span *ngIf="generalService.translationMode === true">
              (G023)
            </span>
            {{ "GLOBAL.G023.value" | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>

  <!-- Payment Successful Confirmation Modal -->
  <div class="nsm-dialog nsm-dialog-animation-fade nsm-dialog-open" ng-reflect-ng-class="nsm-dialog,nsm-dialog-animatio"
    aria-hidden="false" role="dialog" tabindex="-1" aria-modal="true" style="z-index: 1041"
    *ngIf="modalOpen && modalName === MODAL_NAME_SUCCESSFUL_PAYMENT">
    <div class="nsm-content">
      <div class="nsm-body">
        <div class="c-modal__header">
          <h5 class="c-modal__title">
            <span *ngIf="generalService.translationMode === true">
              (C027A)
            </span>
            {{ "CART.C027A.value" | translate }}
          </h5>
        </div>
        <div class="c-modal__body">
          <p>
            <span *ngIf="generalService.translationMode === true">
              (C028A)
            </span>
            {{ "CART.C028A.value" | translate }}
          </p>
          <p>
            <span *ngIf="generalService.translationMode === true">
              (C028B)
            </span>
            {{ "CART.C028B.value" | translate }}
          </p>
          <br />
          <div class="c-buttonGroup c-buttonGroup--submit">
            <button class="b-action b--lg" (click)="closeModal()">
              <!-- Cointinue Shopping -->
              <span *ngIf="generalService.translationMode === true">
                (G055)
              </span>
              {{ "GLOBAL.G055.value" | translate }}
            </button>
          </div>
        </div>
        <div class="c-modal__footer"></div>
      </div>
    </div>
  </div>
</div>